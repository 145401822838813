import { CURRENT_USER_FILTER_ID } from "components/Bar/Filters/utils";
import { LookupEntry, TableColumnType, TableFilterType, TableViewType, TabRecordIDSource } from "types/baTypes";
import { RecordItem, SelectOption } from "types/common";
import { isColumnTextWithOptions } from "./columnUtils";
import {
  APP_FILTER_TYPES,
  CELL_TYPES_WITH_FILES,
  CellType,
  FILTER_OPERATOR,
  FILTER_OPERATOR_NEED_VALUE,
  IS_DELETED_PAGE_FILTER,
  LookupTypes,
  SelectCellTypes,
  USER_TABLE_NAME
} from "./constants";
import { isColumnFileTag } from "./dataUtils";
import { ExtendedSchema } from "./schema";

export const getFilterFieldPathForRecordId = (recordIdSource: TabRecordIDSource) => {
  if (recordIdSource?.columnName) {
    return recordIdSource.columnName;
  }
  let finalPath = "";
  if (recordIdSource?.columnRecordLookupPath) {
    Object.keys(recordIdSource.columnRecordLookupPath).forEach((level: string) => {
      const lookupDetails = recordIdSource.columnRecordLookupPath?.[level];
      if (lookupDetails?.lookupColumns?.length) {
        finalPath += lookupDetails.lookupTableName + "." + lookupDetails.lookupColumns[0];
      } else {
        finalPath += `${level === "0" ? "" : "."}` + level;
      }
    });
  }
  return finalPath;
};

export const constructLookupFilter = ({
  lookupPath,
  type,
  columnHeader,
  isFilterColumnImageRecordType,
  filterOperator
}: {
  lookupPath: { [lookupLevel: string]: LookupEntry };
  type: CellType;
  useColumnLabel?: boolean;
  columnHeader?: string;
  isFilterColumnImageRecordType?: boolean;
  filterOperator?: FILTER_OPERATOR;
}) => {
  let finalFilter = "";
  let finalLookupColumn = "";
  const finalLookupPath = { ...lookupPath };

  if (isFilterColumnImageRecordType) {
    if (lookupPath?.[0].lookupType === LookupTypes.FOREIGN) {
      const { lookupTableName, lookupForeignKey, lookupColumnLabel } = lookupPath[0];
      finalFilter = lookupColumnLabel || lookupForeignKey || lookupTableName;
    } else if (lookupPath?.[0].lookupType === LookupTypes.JOIN) {
      const { lookupTableName, lookupForeignKey, lookupColumnLabel } = lookupPath[1];
      finalFilter = lookupColumnLabel || lookupForeignKey || lookupTableName;
    }

    return {
      finalFilter,
      finalLookupColumn,
      isPeopleForeignKey: false,
      isForeignKey: lookupPath?.[0].lookupType === LookupTypes.FOREIGN
    };
  }
  if (
    lookupPath &&
    CELL_TYPES_WITH_FILES.includes(type) &&
    !(lookupPath?.["0"]?.lookupColumnLabel || "").startsWith("image_")
  ) {
    // Fix lookup path to remove files
    let fileLevelFound = false;
    Object.keys(lookupPath).forEach((lookupLevel) => {
      if (fileLevelFound) {
        delete finalLookupPath?.[lookupLevel];
        fileLevelFound = true;
      }
    });
  }
  if (lookupPath && type === CellType.DEAL) {
    // Handle project separately to link to places id
    // Fix lookup path to remove files
    let dealLevelFound = false;
    Object.keys(lookupPath).forEach((lookupLevel) => {
      const { lookupTableName = "" } = lookupPath?.[lookupLevel] || {};
      if (dealLevelFound) {
        delete finalLookupPath?.[lookupLevel];
      }
      if (lookupTableName === "deals") {
        dealLevelFound = true;
      }
    });
  }

  let isPeopleForeignKey = false;
  const firstLevel = finalLookupPath?.["0"];
  if (
    lookupPath &&
    type === CellType.PEOPLE &&
    !!columnHeader &&
    !["Created By", "Updated By"].includes(columnHeader) // ##HARDCODED Exclude these columns as we pick id from selected filter option, not uuid
  ) {
    if (!!firstLevel?.lookupForeignKey && firstLevel?.lookupTableName === USER_TABLE_NAME) {
      // Directly use this field
      finalFilter = firstLevel?.lookupForeignKey;
      isPeopleForeignKey = true;
    }
  }
  if (finalLookupPath && filterOperator === FILTER_OPERATOR.EMPTY) {
    // Join tables with empty filter use the join table name directly
    finalFilter = firstLevel?.lookupColumnLabel || firstLevel?.lookupForeignKey || firstLevel?.lookupTableName;
  } else if (!isPeopleForeignKey) {
    Object.keys(finalLookupPath || {}).forEach((lookupLevel, keyIdx) => {
      const { lookupTableName, lookupForeignKey, lookupColumnLabel, lookupColumns, lookupDisplayColumn } =
        finalLookupPath[lookupLevel];

      // for projects lookup using foreignKey and when at deals level use the foreignKey first if present
      if (lookupLevel !== "0" && lookupTableName === "deals" && type === CellType.DEAL) {
        finalFilter += `${lookupForeignKey || lookupColumnLabel || lookupTableName}`;
      } else {
        finalFilter += `${lookupColumnLabel || lookupForeignKey || lookupTableName}`;
      }
      if (keyIdx !== Object.keys(finalLookupPath).length - 1) {
        finalFilter += ".";
      } else {
        finalFilter += ".";
        finalLookupColumn = lookupDisplayColumn || lookupColumns?.[0] || "";
      }
    });
  }

  return {
    finalFilter: finalFilter.endsWith(".") ? finalFilter.slice(0, -1) : finalFilter,
    isPeopleForeignKey,
    finalLookupColumn,
    isForeignKey: !!finalLookupPath?.["0"]?.lookupForeignKey
  };
};

// filterString:  Returns the raw PostgREST syntax for the filter names and values
// orOptions: Return or options such as foreignTables
export const constructORFilterFromGroup = (
  filterGroup: TableFilterType[],
  filterId?: string,
  isBooleanFilter = false,
  isNumericFilter = false
) => {
  if (!filterGroup?.length) {
    return;
  }
  let filterString = "";
  const orOptions: any = {};
  filterGroup.forEach((filter: TableFilterType) => {
    const { filterValue, filterOperator, filterField, includeNull, filterLookupPath, isFilterTextSearch } = filter;
    if (filterLookupPath) {
      let mainLookupTableName = "";

      // in case of current user filter and people cell
      if (filterId === CURRENT_USER_FILTER_ID && filter.column?.type === CellType.PEOPLE) {
        filterString += filterField || "";
      } else {
        Object.keys(filterLookupPath || {}).forEach((level: string) => {
          const lookupDetails = filterLookupPath?.[level];
          if (level === "0") {
            orOptions.foreignTable = lookupDetails.lookupTableName;
            mainLookupTableName = lookupDetails.lookupTableName;
          }

          if (lookupDetails?.lookupColumns?.length) {
            filterString +=
              (mainLookupTableName !== lookupDetails.lookupTableName ? lookupDetails.lookupTableName + "." : "") +
              lookupDetails.lookupColumns[0];
          } else {
            filterString +=
              (mainLookupTableName !== lookupDetails.lookupTableName ? lookupDetails.lookupTableName : "") + ".";
          }
        });
      }
    } else {
      filterString += filterField || "";
    }
    const filterFld = filterString;
    switch (filterOperator) {
      case FILTER_OPERATOR.EQUALS:
      case FILTER_OPERATOR.CURRENT_RECORD:
      case FILTER_OPERATOR.CURRENT_DEAL:
        filterString += `.eq.${filterValue}`;
        break;
      case FILTER_OPERATOR.NOT_EQUALS:
        filterString += `.neq.${filterValue}`;
        break;
      case FILTER_OPERATOR.GREATER_THAN:
        filterString += `.gt.${filterValue}`;
        break;
      case FILTER_OPERATOR.GREATER_THAN_EQUALS:
        filterString += `.gte.${filterValue}`;
        break;
      case FILTER_OPERATOR.LESS_THAN:
        filterString += `.lt.${filterValue}`;
        break;
      case FILTER_OPERATOR.LESS_THAN_EQUALS:
        filterString += `.lte.${filterValue}`;
        break;
      case FILTER_OPERATOR.EMPTY:
        filterString += `.is.null`; // Null check
        if (!isBooleanFilter && !isNumericFilter) {
          filterString += `,${filterFld}.eq.`; //  empty string check
        }
        break;
      case FILTER_OPERATOR.NOT_EMPTY:
        filterString += `not.is.null`; // not Null check
        if (!isBooleanFilter && !isNumericFilter) {
          filterString += `,${filterFld}.neq.`; // not empty string check
        }
        break;
      case FILTER_OPERATOR.CONTAINS:
        if (isFilterTextSearch) filterString += `.fts.${filterValue}`;
        break;
    }
    filterString += ",";
  });
  if (filterString.endsWith(",")) {
    filterString = filterString.slice(0, -1);
  }
  return { filterString, orOptions };
};

export const checkTableFiltersValidation = (tableFiltersOption?: TableViewType) => {
  if (!tableFiltersOption) {
    return true;
  }
  const { filters } = tableFiltersOption;
  if (!filters) {
    return true;
  }
  return filters.every(
    (filter) =>
      !FILTER_OPERATOR_NEED_VALUE.includes(filter.filterOperator as FILTER_OPERATOR) ||
      (FILTER_OPERATOR_NEED_VALUE.includes(filter.filterOperator as FILTER_OPERATOR) &&
        filter.filterValue !== undefined &&
        filter.filterValue !== null &&
        filter.filterValue !== "")
  );
};

// Merges filters from different columns into a single filter
// if they are to the same table
export const constructMultiFilter = (filtersMap: { [key: string]: TableFilterType[] }) => {
  let finalFilters: TableFilterType[] = [];
  if (Object.keys(filtersMap).length === 0) {
    return finalFilters;
  }
  Object.keys(filtersMap).forEach((key) => {
    if (key === "single") {
      finalFilters = [...finalFilters, ...filtersMap[key]];
    } else {
      const tableFilters = filtersMap[key];
      if (tableFilters.length > 1) {
        // Check if all filters are for equals
        const inValues: any = [];
        tableFilters.forEach((filter) => {
          if (
            filter.column &&
            filter.column?.lookupPath &&
            (!filter.filterOperator ||
              filter.filterOperator === FILTER_OPERATOR.EQUALS ||
              filter.filterOperator === FILTER_OPERATOR.IN)
          ) {
            if (!Array.isArray(filter.filterValue)) {
              inValues.push(filter.filterValue);
            } else {
              inValues.push(...filter.filterValue);
            }
          } else {
            finalFilters.push(filter);
          }
        });
        if (inValues?.length) {
          finalFilters.push({
            column: tableFilters[0].column,
            filterOperator: FILTER_OPERATOR.IN,
            filterValue: [...inValues],
            isFilterColumnImageRecordType: tableFilters[0].isFilterColumnImageRecordType
          });
        }
      } else {
        finalFilters.push(tableFilters[0]);
      }
    }
  });
  return finalFilters;
};

// Returns true if column is single level lookup with a foreign key
export const isColumnSingleLevelFKLookup = (column: TableColumnType) => {
  if (!column?.isLookup) return false;

  const { lookupPath } = column;
  const lookupLevels = Object.keys(lookupPath || {});
  if (lookupLevels.length !== 1) return false;
  return !!lookupPath?.[lookupLevels[0]]?.lookupForeignKey;
};

// Special hard coded case to check for file type lookup
export const isColumnFileType = (column: TableColumnType) => {
  return (
    column.type === CellType.BADGE &&
    ((column?.isLookup &&
      column?.lookupPath?.[0]?.lookupForeignKey === "file_id" &&
      Object.keys(column?.lookupPath || {}).length === 1) ||
      column?.name === "file_type")
  );
};

// Returns filter value for a filter
// Only used for view level filters not page filters
export const getSupabaseFilterValue = (filter: TableFilterType) => {
  let finalFilterValue = filter.filterValue;
  const { filterValue, filterOperator } = filter;
  let isFilesTagColumn = false;
  if (filter.column?.id && filter.column?.isLookup && !filter?.column?.isTextArray) {
    const { column } = filter;
    isFilesTagColumn = isColumnFileTag(column);
    if (Array.isArray(filterValue) && filterValue.length) {
      finalFilterValue =
        filterOperator === FILTER_OPERATOR.IN
          ? filterValue.map((fItem: RecordItem) => {
              if (column.type === CellType.DEAL && fItem?.record?.projectId) {
                return fItem?.record?.projectId;
              }
              return isFilesTagColumn && fItem?.record?.tag_id ? fItem?.record?.tag_id : fItem?.record?.id;
            })
          : isFilesTagColumn && (filterValue as RecordItem)?.record?.tag_id
            ? (filterValue as RecordItem[])?.[0]?.record?.tag_id
            : filter.isViewFilter
              ? (filterValue as RecordItem[])?.[0]?.title || ""
              : column.type === CellType.DEAL && (filterValue as RecordItem[])?.[0]?.record?.projectId
                ? (filterValue as RecordItem[])?.[0]?.record?.projectId
                : (filterValue as RecordItem[])?.[0]?.record?.id;
    } else {
      finalFilterValue =
        isFilesTagColumn && (filterValue as RecordItem)?.record?.tag_id
          ? (filterValue as RecordItem)?.record?.tag_id
          : filter.isViewFilter
            ? (filterValue as RecordItem)?.title || ""
            : (filterValue as RecordItem)?.record?.id || filterValue;
      if (column.type === CellType.DEAL && (filterValue as RecordItem)?.record?.projectId) {
        finalFilterValue = (filterValue as RecordItem)?.record?.projectId;
      }
    }
  } else if (
    (filterOperator === FILTER_OPERATOR.IN || filterOperator === FILTER_OPERATOR.NOT_IN) &&
    typeof filterValue === "string"
  ) {
    finalFilterValue = filterValue.split(",").filter((f) => f.trim());
  } else if (filter.column?.id && isColumnTextWithOptions(filter.column)) {
    if (Array.isArray(filterValue) && filterValue.length) {
      finalFilterValue = filter.column?.isTextArray
        ? filterValue
        : filterOperator === FILTER_OPERATOR.IN
          ? filterValue.map((fItem: RecordItem) => (filter.isViewFilter ? fItem.title : fItem?.record?.id))
          : filter.isViewFilter || filter.column?.isTextArray
            ? (filterValue as RecordItem[])?.[0]?.title
            : (filterValue as RecordItem[])?.[0]?.record?.id;
    } else {
      finalFilterValue = filter.column?.isTextArray
        ? (filterValue as string)?.includes(",")
          ? (filterValue as string).split(",")
          : filterValue
            ? [filterValue]
            : ""
        : filter.isViewFilter
          ? (filterValue as RecordItem)?.title || filterValue
          : (filterValue as RecordItem)?.value || filterValue;
    }
  }

  return finalFilterValue;
};

export const getForeignKeyForFilterType = (filterType: APP_FILTER_TYPES) => {
  switch (filterType) {
    case APP_FILTER_TYPES.LOOKUP_PATH:
      return "lookup_filter_id";
    case APP_FILTER_TYPES.COLUMN_OPTIONS:
      return "column_options_filter_id";
    case APP_FILTER_TYPES.ADD_TAB_FILTER:
      return "add_page_filter_id";
    case APP_FILTER_TYPES.ADD_FILTER:
      return "add_filter_id";
    case APP_FILTER_TYPES.PAGE_FILTER:
      return "page_filters_id";
    case APP_FILTER_TYPES.SECTION_FILTER:
      return "ui_filters_id";
    default:
      return "";
  }
};

export const getTableNameForFilters = ({
  pageColumnId,
  viewColumnId,
  tabId,
  pageId,
  sectionId
}: {
  pageColumnId?: string;
  tabId?: string;
  pageId?: string;
  sectionId?: string;
  viewColumnId?: string;
}) => {
  if (pageColumnId) {
    return "ui_pages_columns";
  } else if (tabId) {
    return "ui_tabs";
  } else if (pageId) {
    return "pages";
  } else if (sectionId) {
    return "ui_column_sections";
  } else if (viewColumnId) {
    return "ui_views_columns";
  }
  return "";
};

export const getFilterValueAndRelatedProps = (
  filter: TableFilterType,
  value: string | SelectOption | boolean | string[]
) => {
  let useFilterLookupColumn = false;
  // Hardcoded condition for file badges
  if (filter.column && isColumnFileType(filter.column)) {
    useFilterLookupColumn = true;
  }
  const filterVal = getSupabaseFilterValue({
    ...filter,
    filterValue: value,
    isViewFilter: !!useFilterLookupColumn
  });

  return { filterValue: filterVal, useFilterLookupColumn };
};

export const getFilterValueFromDbFilterValue = ({
  filterColumn,
  filterValue
}: {
  filterColumn?: TableColumnType;
  extendedSchema?: ExtendedSchema;
  filterValue?: string;
}) => {
  if (filterColumn) {
    let finalFilterValue: any = filterValue ?? "";
    if (filterColumn?.isLookup) {
      if ((SelectCellTypes.includes(filterColumn.type) || filterColumn.type === CellType.BADGE) && finalFilterValue) {
        if (filterColumn.isMultiple) {
          finalFilterValue = finalFilterValue.split(",").map((fItem: string) => ({
            value: fItem,
            title: fItem,
            record: {
              id: fItem
            }
          }));
        } else {
          finalFilterValue = {
            value: finalFilterValue,
            title: finalFilterValue,
            record: {
              id: finalFilterValue
            }
          };
        }
      }
    }
    if (filterColumn?.dbType?.format === "boolean" || filterColumn.type === CellType.BOOLEAN) {
      finalFilterValue = filterValue === "true";
    }
    if (filterColumn?.isTextArray && filterValue) {
      finalFilterValue = filterValue.split(",");
    }
    return finalFilterValue;
  }
  return filterValue;
};

// If true filter cannot use emtpy string in value
export const isFilterNonTextType = (filter: TableFilterType) => {
  return (
    filter.column?.dbType?.format === "datetime" ||
    filter.column?.type === CellType.DATETIME ||
    filter.column?.dbType?.format === "boolean" ||
    filter.column?.type === CellType.BOOLEAN ||
    filter.filterDbType === "boolean" ||
    filter.column?.dbType?.format === "numeric" ||
    filter.column?.dbType?.format === "jsonb" ||
    filter.column?.dbType?.format === "uuid" ||
    filter.filterDbType === "numeric" ||
    filter.filterDbType === "uuid" ||
    filter.filterDbType === "jsonb"
  );
};

export const getFinalTableFilterOptions = (tableFiltersOption: { filters: TableFilterType[] }) => {
  const filters = [];
  if (tableFiltersOption?.filters?.length) {
    let hasIsDeletedFilter = false;
    filters.push(
      ...tableFiltersOption?.filters.filter((filter) => {
        if (filter?.filterField === "is_deleted") {
          hasIsDeletedFilter = true;
        }
        return true;
      })
    );
    if (!hasIsDeletedFilter) {
      filters.push(IS_DELETED_PAGE_FILTER);
    }
  }

  return filters;
};
